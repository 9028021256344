<template>
  <div
    v-if="media.length > 0"
    class="paired-media"
    :class="`paired-media-${media.length}`"
  >
    <div
      v-for="(item, index) in media"
      :key="`item-${index}`"
      class="paired-media__media"
    >
      <template v-if="item.mediaType && item.mediaType === 'image'">
        <nuxt-img
          v-if="item.image"
          :src="isDesktop ? item.image.desktop : item.image.mobile"
          loading="lazy"
        />
      </template>
      <div v-else class="paired-media__media__video">
        <template v-if="getCorrectVideo(item)">
          <ClientOnly>
            <vueVimeoPlayer
              v-bind="getVimeoPlayerOptions(item, index)"
              class="paired-media__media__video__player"
            />
          </ClientOnly>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from '@nuxtjs/composition-api';
import { useWindow } from '~/composables';
import { vueVimeoPlayer } from 'vue-vimeo-player';

export default defineComponent({
  name: 'PairedMedia',
  components: {
    vueVimeoPlayer,
  },
  props: {
    media: {
      type: Array,
      default: () => [],
    },
  },
  setup() {
    const { isDesktop } = useWindow();

    const getCorrectVideo = (item) =>
      isDesktop.value ? item.videoDesktop : item.videoMobile;

    const getVimeoPlayerOptions = (item, index) => {
      const vimeoPlayerOptions = {
        ref: `paired-media-player-${index}`,
        autoplay: true,
        controls: false,
        loop: true,
        options: {
          muted: true,
          autopause: false,
        },
      };
      const video = getCorrectVideo(item);
      vimeoPlayerOptions[video.includes('http') ? 'video-url' : 'video-id'] =
        video;
      return vimeoPlayerOptions;
    };

    return {
      isDesktop,
      getCorrectVideo,
      getVimeoPlayerOptions,
    };
  },
});
</script>

<style lang="scss" scoped>
.paired-media {
  display: grid;
  &__media {
    width: 100%;
    &__video {
      &__player {
        padding: 200% 0 0 0;
        position: relative;
        width: 100vw;
        @include desktop-boxed;
      }
    }
  }
  &-2 {
    .paired-media__media {
      &__video {
        &__player {
          padding: 100% 0 0 0;
        }
      }
    }
  }
  @include from-desktop-min {
    &__media {
      &__video {
        &__player {
          padding: 50% 0 0 0;
        }
      }
    }
    &-2 {
      grid-template-columns: repeat(2, 1fr);
      width: 100%;
      .paired-media__media {
        &__video {
          &__player {
            width: 100%;
          }
        }
      }
    }
  }
}
</style>

<style lang="scss">
.paired-media__media__video__player {
  iframe,
  object,
  embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
</style>
