<template>
  <div class="custom-product-card__text">
    <span class="custom-product-card__text__category">{{ category }}</span>
    <nuxt-link :to="localePath(`/${urlKey}`)">
      <span class="custom-product-card__text__name">{{ name }}</span>
    </nuxt-link>
  </div>
</template>

<script>
import { defineComponent, inject } from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'ProductCardText',
  setup() {
    const { name, categories, url_key: urlKey } = inject('product');

    return { name, category: categories?.[0]?.name || '', urlKey };
  },
});
</script>

<style lang="scss" scoped>
.custom-product-card__text {
  display: flex;
  flex-direction: column;
  padding-bottom: 0.25rem;
  gap: 0.25rem;
  &__category {
    @include label-s;
    text-transform: uppercase;
  }
  &__name {
    @include mobile-h6;
    text-transform: capitalize;
    color: var(--c-black);
  }
}
</style>
