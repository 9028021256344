<template>
  <nuxt-link :to="localePath(`/${product.url_key}`)">
    <div
      class="custom-product-card__image"
      @mouseover="isHovering = true"
      @mouseleave="isHovering = false"
    >
      <nuxt-img
        v-show="!isHovering"
        class="custom-product-card__image__image"
        :src="thumbnailSource"
        format="webp"
      />
      <nuxt-img
        v-show="isHovering"
        loading="lazy"
        preload
        class="custom-product-card__image__image"
        :src="rolloverSource"
        format="webp"
      />
    </div>
  </nuxt-link>
</template>

<script>
import {
  defineComponent,
  inject,
  computed,
  ref,
} from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'ProductCardImage',
  setup() {
    const product = inject('product');
    const thumbnailSource = computed(() =>
      product?.thumbnail?.url
        ? `${product?.thumbnail?.url}?w=600`
        : '/logo/logo-black.svg'
    );
    const rolloverSource = computed(() =>
      product?.rollover_image?.url
        ? `${product?.rollover_image?.url}?w=600`
        : thumbnailSource.value
    );

    const isHovering = ref(false);
    const currentImageSource = computed(() =>
      isHovering.value ? rolloverSource.value : thumbnailSource.value
    );

    return {
      currentImageSource,
      thumbnailSource,
      rolloverSource,
      isHovering,
      product,
    };
  },
});
</script>

<style lang="scss">
.custom-product-card__image {
  position: relative;
  display: flex;
  &__image {
    width: 100%;
    aspect-ratio: 1/1;
    object-fit: contain;
    z-index: 1;
  }
}
</style>
