<template>
  <div class="custom-product-card__colors" v-if="firstFourVariants.length > 0">
    <span class="custom-product-card__colors__available--mobile">
      {{ $t(`Available in ${firstFourVariants.length} colors`) }}
    </span>
    <span class="custom-product-card__colors__available--desktop">
      <span
        v-for="(color, index) in firstFourVariants"
        :key="`variant-${productId}-${index}`"
        v-tooltip="{
          content: $t(color.label || ''),
          classes: ['color-tooltip'],
        }"
        class="custom-product-card__colors__available--desktop__variant"
      >
        <nuxt-img
          v-if="color.swatch_data[0] && color.swatch_data[0].thumbnail"
          :src="color.swatch_data[0].thumbnail.url"
          class="custom-product-card__colors__available--desktop__variant__image"
        />
        <div
          class="custom-product-card__colors__available--desktop__hex"
          v-else-if="hexCode(color)"
          :style="{ backgroundColor: hexCode(color) }"
        />
      </span>
    </span>
    <span
      class="custom-product-card__colors__rest"
      v-show="colors.length - 4 > 0"
    >
      {{ $t(`+${drop(colors, 4).length} colors`) }}
    </span>
  </div>
  <div v-else class="custom-product-card__colors--placeholder"></div>
</template>

<script>
import { defineComponent, inject, computed } from '@nuxtjs/composition-api';
import { drop } from 'lodash';

export default defineComponent({
  name: 'ProductCardColors',
  setup() {
    const { configurable_options: configurableOptions, sku: productId } =
      inject('product');

    const colors = computed(() => {
      const optionValues = configurableOptions?.find(
        (c) => c.attribute_code === 'sdc_colore_vetro'
      );
      return optionValues?.values ?? [];
    });

    const firstFourVariants = computed(() => colors.value.slice(0, 4));

    const hexCode = (optionValue) => optionValue?.swatch_data[0]?.hex_code;

    return {
      colors,
      drop,
      firstFourVariants,
      productId,
      hexCode,
    };
  },
});
</script>

<style lang="scss">
.custom-product-card__colors {
  display: flex;
  justify-content: space-between;
  padding: 1rem 0;
  &__available,
  &__rest {
    @include paragraph-s;
    &--invisible {
      visibility: hidden;
    }
  }
  min-height: 4.375rem;
  &--placeholder {
    height: 3rem;
  }
}

@include to-mobile-max {
  .custom-product-card__colors {
    display: flex;
    flex-direction: column;
    &__available {
      &--desktop {
        display: none;
      }
    }
  }
}
@include from-tablet-min {
  .custom-product-card__colors {
    display: flex;
    flex-direction: column;
    gap: 0.875rem;
    &__available {
      &--mobile {
        display: none;
      }
      &--desktop {
        display: flex;
        justify-content: flex-start;
        gap: var(--spacer-xs);

        &__variant {
          width: 1.5rem;
          height: 0.5rem;
          &__image {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        &__hex {
          min-width: 1.5rem;
          min-height: 0.5rem;
        }
      }
    }
  }
}
.color-tooltip {
  z-index: 2;
  .tooltip-inner {
    position: relative;
    padding: var(--spacer-xs) var(--spacer-sm);
    bottom: var(--spacer-sm);
    background: var(--c-white);
    border: var(--general-border);
    border-radius: var(--spacer-sm);
    @include label-s;
    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 50%;
      width: 0;
      height: 0;
      border: 8px solid transparent;
      border-top-color: #ffffff;
      border-bottom: 0;
      margin-left: -8px;
      margin-bottom: -8px;
      filter: drop-shadow(0 0 0 #000);
    }
  }
}
</style>
