<template>
  <div
    class="custom-product-card"
    :class="isSlider && 'custom-product-card__slider'"
  >
    <ProductCardImage />
    <ProductCardLabels />
    <ProductCardColors />
    <ProductCardText />
    <ProductCardPrice :hidePrice="hidePrice" />
  </div>
</template>

<script>
import { defineComponent, provide, computed } from '@nuxtjs/composition-api';

import {
  ProductCardImage,
  ProductCardColors,
  ProductCardText,
  ProductCardPrice,
  ProductCardLabels,
} from '~/components/General/ProductCard/Internal';

import { useMarkets } from '~/composables';

export default defineComponent({
  name: 'ProductCard',
  components: {
    ProductCardImage,
    ProductCardColors,
    ProductCardText,
    ProductCardPrice,
    ProductCardLabels,
  },
  props: {
    product: {
      type: Object,
      required: true,
    },
    isSlider: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const { isFarEastMarket } = useMarkets();
    const { product } = props;

    const hidePrice = computed(() => {
      return (
        product.variants?.[0]?.product?.sdc_ondemand || isFarEastMarket.value
      );
    });

    provide('product', product);

    return {
      hidePrice,
    };
  },
});
</script>

<style lang="scss">
.custom-product-card {
  * {
    box-sizing: border-box;
  }
  box-sizing: border-box;
  position: relative;
  width: 100%;
  @include from-desktop-min {
    max-width: calc(100vw / 3);
  }
  padding: var(--spacer-base) var(--spacer-base) 1.875rem var(--spacer-base);
  border-bottom: var(--general-border);
}
@include to-mobile-max {
  .custom-product-card {
    &:nth-child(1) {
      border-top: var(--general-border);
    }
  }
}
@include from-tablet-min {
  .custom-product-card {
    &:nth-child(-n + 3) {
      border-top: var(--general-border);
    }
    border-right: var(--general-border);
    &:nth-child(3n + 1):not(.custom-product-card__slider) {
      border-left: var(--general-border);
    }
  }
}
</style>
