<template>
  <div class="custom-product-card__labels">
    <span class="custom-product-card__labels--label" v-if="discountAmount">
      {{ `-${discountAmount}%` }}
    </span>
    <span class="custom-product-card__labels--label" v-if="onSale">
      {{ onSale }}
    </span>
    <span class="custom-product-card__labels--label" v-if="isOnDemand">
      {{ $t('on demand') }}
    </span>
  </div>
</template>

<script>
import { defineComponent, inject, computed } from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'ProductCardLabels',
  components: {},
  props: {},
  setup() {
    const {
      price_range: {
        minimum_price: {
          regular_price: { value: regularPrice },
          final_price: { value: specialPrice } = {},
        },
      },
      variants: variants,
    } = inject('product');

    const isOnDemand = variants?.[0]?.product?.sdc_ondemand;

    const onSale = computed(() =>
      regularPrice < specialPrice ? 'on sale' : false
    );
    const discountAmount = computed(() =>
      specialPrice ? Math.round((1 - specialPrice / regularPrice) * 100) : null
    );

    return {
      onSale,
      isOnDemand,
      discountAmount,
    };
  },
});
</script>

<style lang="scss" scoped>
.custom-product-card {
  &__labels {
    position: absolute;
    top: 1.875rem;
    left: 1.875rem;
    display: flex;
    gap: 0.5rem;
    &--label {
      text-transform: uppercase;
      @include label-m;
      width: fit-content;
      padding: 0.6563rem;
      z-index: 2;
      background-color: var(--c-white);
      border-radius: 6.25rem;
    }
  }
}
</style>
