<template>
  <div class="progress-bar" :style="customStyle" />
</template>

<script>
import { defineComponent } from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'ProgressBar',
  components: {},
  props: {
    width: {
      type: String,
      default: '100%',
    },
    height: {
      type: String,
      default: '0.25rem',
    },
    barColor: {
      type: String,
      default: '#ffffff',
    },
    backgroundColor: {
      type: String,
      default: '#d2d2d2',
    },
    duration: {
      type: String,
      default: '5s',
    },
  },
  setup(props) {
    return {
      customStyle: {
        width: props.width,
        height: props.height,
        background: `linear-gradient(${props.barColor} 0 0) left/0% 100% no-repeat ${props.backgroundColor}`,
        'animation-duration': props.duration,
      },
    };
  },
});
</script>

<style lang="scss" scoped>
.progress-bar {
  animation: progress-bar-infinite 5s infinite linear;
}

@keyframes progress-bar-infinite {
  100% {
    background-size: 100% 100%;
  }
}
</style>
