<template>
  <div class="custom-product-card__price">
    <div v-show="!hidePrice">
      <span class="custom-product-card__price__text" v-if="regularPrice > 0">
        {{ `✺ ${$t('from')} ` }}
      </span>
      <span
        class="custom-product-card__price__special"
        v-if="specialPriceActive"
      >
        {{ $fc(specialPrice) }}
      </span>
      <span
        v-if="regularPrice > 0"
        class="custom-product-card__price__regular"
        :class="
          specialPriceActive && 'custom-product-card__price__regular--barred'
        "
      >
        {{ $fc(regularPrice) }}
      </span>
    </div>
    <span v-show="hidePrice" class="custom-product-card__price__regular">
      {{ `✺ ${$t('request a price quote')}` }}
    </span>
    <div v-show="customizable" class="custom-product-card__price__customizable">
      <Ruler class="custom-product-card__price__customizable--icon" />
      {{ $t('Customizable') }}
    </div>
  </div>
</template>

<script>
import { defineComponent, inject, computed } from '@nuxtjs/composition-api';
import { Ruler } from '~/components/General/Icons';

export default defineComponent({
  name: 'ProductCardPrice',
  components: {
    Ruler,
  },
  props: {
    hidePrice: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const {
      sdc_custom_mirror,
      price_range: {
        minimum_price: {
          regular_price: { value: regularPrice = '' } = {},
          final_price: { value: specialPrice = '' } = {},
        },
      } = {},
    } = inject('product');

    const specialPriceActive = computed(
      () => specialPrice && specialPrice < regularPrice && specialPrice > 0
    );

    const customizable = computed(() => sdc_custom_mirror);

    return {
      regularPrice,
      specialPrice,
      specialPriceActive,
      customizable,
    };
  },
});
</script>

<style lang="scss" scoped>
.custom-product-card__price {
  width: 100%;
  display: flex;
  justify-content: space-between;
  &__regular,
  &__special,
  &_customizable {
    @include paragraph-m;
  }
  &__regular {
    &--barred {
      text-decoration: line-through;
      color: var(--c-dark-gray);
    }
  }
  &__customizable {
    &--icon {
      transform: rotate(-45deg);
    }
  }
}
</style>
